<ngx-loading-bar [height]="'6px'" [diameter]="'13px'"></ngx-loading-bar>
<p-toast></p-toast>
<div class="wrapper" *ngIf="auth.isAuthenticated$ | async">
	<div class="logo-wrapper" [routerLink]="['/']"></div>
	<div class="header"></div>
	<div class="sidebar">
		<ul class="navbar">
			<li routerLinkActive="active">
				<a [routerLink]="['/scope-snapshot']" pRipple>
					<div class="ficon ficon-IconSetsFlag"></div>
					<span class="navbar-item-text">Flags &amp; Values</span>
				</a>
			</li>
			<li routerLinkActive="active">
				<a [routerLink]="['/scopes-and-snapshots']" pRipple>
					<div class="ficon ficon-AnalyticsQuery"></div>
					<span class="navbar-item-text">Scopes &amp; Snapshots</span>
				</a>
			</li>
			<li routerLinkActive="active">
				<a [routerLink]="['/scope-variables']" pRipple>
					<div class="ficon ficon-Variable2"></div>
					<span class="navbar-item-text">Scope Variables</span>
				</a>
			</li>
			<li routerLinkActive="active">
				<a [routerLink]="['/deployments']" pRipple>
					<div class="ficon ficon-Rocket"></div>
					<span class="navbar-item-text">Deployments</span>
				</a>
			</li>
			<li routerLinkActive="active">
				<a [routerLink]="['/history']" pRipple>
					<div class="ficon ficon-FullHistory"></div>
					<span class="navbar-item-text">History</span>
				</a>
			</li>
			<!-- <li routerLinkActive="active">
				<a [routerLink]="['/roles']" pRipple>
					<div class="ficon ficon-SecurityGroup"></div>
					<span class="navbar-item-text">Roles</span>
				</a>
			</li> -->
			<li routerLinkActive="active">
				<a [routerLink]="['/users']" pRipple>
					<div class="ficon ficon-Group"></div>
					<span class="navbar-item-text">Users</span>
				</a>
			</li>
		</ul>
		<a href="//scoperoot.com" target="_blank" class="scoperoot-logo"><span class="powered-by">Powered by</span></a>
		<app-user-profile></app-user-profile>
	</div>
	<!-- Hide the scrollbars at this level if the scope snapshot page is loaded because that page is going to add the scrollbars on it's own. It is really fancy with its two column layout as well as sticky elements and so the UI would get really wonky if we were to try to add scrollbars here - for example, a horizontal scrollbar at this level would cut off the .actions-bar. So, simply hide the scrollbars here and let the scope snapshot page have its own scrollbars. -->
	<div class="main" [class]="{
		'overflowHidden': isScopeSnapshotPage()
	}">
		<router-outlet></router-outlet>
	</div>
</div>
<div class="logged-out-page" *ngIf="!(auth.isAuthenticated$ | async)">
	<div class="logo"></div>
</div>
